<template>
  <div style=" height:100%;overflow-x: hidden;
    overflow-y: auto;">

  <div style="position: sticky;top:0;left:0;">
 <div class="wechatUserTitle">
      <div>
        <img style="width: 80%; height: 80%" :src="wechatUser.wImg" alt="" />
      </div>
      <div>
        <div style="text-align: left; width: 100%; line-height: 30px">
          {{ wechatUser.wName }}
        </div>
        <div style="text-align: left; width: 100%">
          <div >
            余额：<span style="font-size: 16px">{{ wechatUser.wBalance }}</span>
          </div>
          <div
            
            @click="recharge"
            style="
              text-align: center;
              font-weight: bolder;
              margin: 10px 0;
              color: green;
            "
          >
            充值
          </div>
          <van-overlay style="z-index:6666;" :show="show" @click="show = false">
            <div class="wrapper" @click="show = false">
              <div class="rechargeBox" @click.stop>
                <div>
                  <div>充值金额:</div>
                  <el-radio-group v-model="money" style="width: 100%">
                    <div class="moneyBox">
                      <div>
                        <el-radio-button size="medium" :label="10"
                          >10元</el-radio-button
                        >
                      </div>
                      <div>
                        <el-radio-button size="medium" :label="30"
                          >30元</el-radio-button
                        >
                      </div>
                      <div>
                        <el-radio-button size="medium" :label="50"
                          >50元</el-radio-button
                        >
                      </div>
                      <div>
                        <el-radio-button size="medium" :label="100"
                          >100元</el-radio-button
                        >
                      </div>
                    </div>
                  </el-radio-group>
                </div>
                <div class="footer">
                  <van-button @click="topPay" size="large" round type="primary"
                    >立即支付</van-button
                  >
                </div>
              </div>
            </div>
          </van-overlay>
        </div>
      </div>
      <div>
       
      </div>
    </div>
  <van-tabs v-model="historyFrom.topPay"  @change="setTopPay">
  <van-tab title="支付记录" :name="0"></van-tab>
  <van-tab title="充值记录" :name="1"></van-tab>
</van-tabs>
    </div>

<van-list
  v-model="loading"
  :finished="finished"
  finished-text="没有更多了"
  @load="onLoad"
>
  <div v-for="(item,index) in payList" :key="index" class="payItem">

    <div class="item"><span class="itenName">商 品 名 ：</span><span class="itemBody">{{item.description}}</span> </div>
    <div class="item"><span class="itenName">购买日期：</span><span class="itemBody">{{item.createTime}}</span></div>
    <div class="item"><span class="itenName">订 单 号 ：</span><span class="itemBody">{{item.outTradeNo}}</span></div>
    <div class="item"><span class="itenName">支付金额：</span><span class="itemBody">￥{{item.moneyNum}}元</span></div>
    <div class="item"><span class="itenName">支付方式：</span><span class="itemBody" v-if="item.payType==0">微信支付</span>
    <span class="itemBody" v-else>余额支付</span></div>
    <div  class="item"><span class="itenName">支付状态：</span>
    <span class="itemBody" v-if="item.payState==1">支付成功</span><span class="itemBody" v-else-if="item.payState==2">支付失败</span></div>
    <div v-if="item.sendState||item.sendState==0" class="item"><span class="itenName">设备状态：</span><span class="itemBody" v-if="item.sendState==0">执行中...</span>
    <span class="itemBody" v-else-if="item.sendState==1">执行成功</span><span class="itemBody" v-else-if="item.sendState==2">执行失败</span></div>

  </div>
</van-list>
  </div>
</template>

<script>
let that;
export default {
  data() {
    return {
      money:0,
    show:false,
    wechatUser:{},
    finished: false,
    loading: false,
    total: -1,
      historyFrom: {
        current: 1,
        size: 20,
        wechatOpenId: "",
        topPay:0,
      },
      payList: [],
    };
  },
  mounted() {
    that = this;
    that.historyFrom.wechatOpenId = that.$route.query.openId;
    that.getBalance();
  },
  methods: {
      recharge() {
      that.money = 0;
      that.show = true;
    },
     topPay() {
      if (that.money) {
        let topPayFrom = {
          wechatOpenId: that.historyFrom.wechatOpenId,
          total: that.money * 100,
        };
        that.$api.payParam.topPay(topPayFrom).then((res) => {
          window.WeixinJSBridge.invoke(
            "getBrandWCPayRequest",
            res.data,
            function (response) {
              that.show = false;
              that.getBalance();
              if (response.err_msg == "get_brand_wcpay_request:ok") {
                that.$message({
                  type: "success",
                  message: "支付成功",
                });
              } else {
                that.$message({
                  type: "warning",
                  message: "支付失败",
                });
              }
            }
          );
        });
      }
    },
    setTopPay(){
        that.payList=[],
        that.loading = true;
        that.finished=false;
        that.getPayLog();
    },
    onLoad(){
        that.loading = true;
        if (that.payList.length==that.total) {
        that.loading = false;
        that.finished=true;
        }else{
        that.getPayLog();
        }
        
    },
    getPayLog() {
      that.$api.payParam.getPayLog(that.historyFrom).then((res) => {
          that.loading = false;
        if (res.code == 200) {
            res.data.records.forEach(element => {
                element.moneyNum=(element.moneyNum/100).toFixed(2);
                that.payList.push(element);
            });
          that.total = res.data.total;
          
        }
      });
    },
       // 获取用户信息
    getBalance() {
      // that.historyFrom.wechatOpenId
      that.$api.payParam.getBalance(that.historyFrom.wechatOpenId).then((res) => {
        if (res.code == 200) {
          that.wechatUser = res.data;
          that.wechatUser.wBalance = (that.wechatUser.wBalance / 100).toFixed(
            2
          );
        }
      });
    },
  },
};
</script>

<style scoped>
*{
    font-size: 15px;
}
.payItem{
    width: 100%;
    border-bottom: 1px solid #ebedf0;
    margin: 0 10px;
    padding: 20px 0;
}
.item{
    display: flex;
    justify-content: left;
    margin: 2px 0;
}
.itenName{
    display: inline-block;
    width: 100px;
    text-align: right;

}
.wechatUserTitle {
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-around;
  background: #fff;
}
.wechatUserTitle > div {
  width: 30vw;
  height: 30vw;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.wechatUserTitle div {
  font-size: 16px;
  
}
.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.rechargeBox {
  width: 80%;
  height: 70%;
  box-sizing: border-box;
  padding: 20px 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: #fff;
  border-radius: 12px;
}
::v-deep .el-radio-button {
  width: 100%;
}
::v-deep .el-radio-button__inner {
  width: 100%;
  display: inline-block;
}
.rechargeBox {
  width: 80%;
  height: 70%;
  box-sizing: border-box;
  padding: 20px 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: #fff;
  border-radius: 12px;
}
::v-deep .el-radio-button {
  width: 100%;
}
::v-deep .el-radio-button__inner {
  width: 100%;
  display: inline-block;
}
.moneyBox > div {
  margin: 10px 0;
}
::v-deep .van-overlay{
  z-index: 9999;
}
</style>